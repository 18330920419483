<template>
    <section
        class="GalleryBlock"
        v-if="
            slice.primary.image_1.url ||
            slice.primary.image_2.url ||
            slice.primary.image_3.url
        "
    >
        <div class="GalleryBlock__wrapper">
            <template v-if="slice.primary.image_1.url">
                <SmartImage
                    :id="sliceId + '--image-1'"
                    class="GalleryBlock__image"
                    :url="slice.primary.image_1.url"
                    :width="slice.primary.image_1.dimensions.width"
                    :height="slice.primary.image_1.dimensions.height"
                    :srcs="[{ width: 400 }, { width: 800 }, { width: 1200 }]"
                    positioning="relative"
                />
            </template>
            <template v-if="slice.primary.image_2.url">
                <SmartImage
                    :id="sliceId + '--image-2'"
                    class="GalleryBlock__image"
                    :url="slice.primary.image_2.url"
                    :width="slice.primary.image_2.dimensions.width"
                    :height="slice.primary.image_2.dimensions.height"
                    :srcs="[{ width: 400 }, { width: 800 }, { width: 1200 }]"
                    positioning="relative"
                />
            </template>
            <template v-if="slice.primary.image_3.url">
                <SmartImage
                    :id="sliceId + '--image-3'"
                    class="GalleryBlock__image"
                    :url="slice.primary.image_3.url"
                    :width="slice.primary.image_3.dimensions.width"
                    :height="slice.primary.image_3.dimensions.height"
                    :srcs="[{ width: 400 }, { width: 800 }, { width: 1200 }]"
                    positioning="relative"
                />
            </template>
        </div>
    </section>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.GalleryBlockSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const sliceId = props.slice.id.replace('$', '-')

onMounted(() => {
    gsap.matchMedia().add(`(min-width: 651px)`, () => {
        if (props.slice.primary.image_1.url) {
            gsap.to('#' + sliceId + '--image-1', {
                scrollTrigger: {
                    trigger: '#' + sliceId + '--image-1',
                    start: '-50% bottom',
                    end: 'bottom top',
                    scrub: 1,
                },
                ease: 'ease',
                y: '100px',
            })
        }

        if (props.slice.primary.image_2.url) {
            gsap.to('#' + sliceId + '--image-2', {
                scrollTrigger: {
                    trigger: '#' + sliceId + '--image-2',
                    start: '-50% bottom',
                    end: 'bottom top',
                    scrub: 2,
                },
                ease: 'ease',
                y: '-100px',
            })
        }

        if (props.slice.primary.image_3.url) {
            gsap.to('#' + sliceId + '--image-3', {
                scrollTrigger: {
                    trigger: '#' + sliceId + '--image-3',
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: 3,
                },
                ease: 'ease',
                y: '-10px',
            })
        }
    })
})
</script>

<style lang="scss">
.PlainTextBlock + .GalleryBlock {
    margin-top: 4rem;
}

.GalleryBlock {
    grid-column: 2 / span 22;
    margin-top: 8rem;

    @include large-up {
        margin-top: 11.25rem;
    }
}

.GalleryBlock__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;

    @include medium-up {
        flex-direction: row;
        justify-content: center;
        column-gap: 1rem;
    }

    @include large-up {
        gap: 3.125rem;
    }
}

.GalleryBlock__image {
    width: 100%;

    @include medium-up {
        width: 40%;
    }
}
</style>
